export function formatDate(
  str,
  options = { year: "numeric", month: "numeric", day: "numeric" }
) {
  if (!str) return "";
  const date = new Date(str);

  date.setDate(date.getDate() + 1);

  return date.toLocaleDateString("pt-BR", options);
}
