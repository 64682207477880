<template>
  <div :class="['d-flex', alignClass]">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      :class="[
        'px-4 px-md-8 px-lg-12 text-body-1 text-none text-uppercase font-weight-bold rounded-lg',
      ]"
      :color="!secondary ? 'primary' : 'secondary'"
      height="48px"
      depressed
      :type="type"
    >
      <slot />
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: "left",
      validator: (val) => ["left", "center", "right"].includes(val),
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
  },
  computed: {
    alignClass() {
      const classes = {
        left: "justify-start",
        center: "justify-center",
        right: "justify-end",
      };

      return classes[this.align];
    },
  },
};
</script>
