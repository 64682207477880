var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative slides slop"},[(_vm.loading)?_c('LoadingWidget'):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(!_vm.loading && _vm.items.length)?_c('div',[_c('hooper-container',{ref:"carousel",staticClass:"slides-container",staticStyle:{"height":"auto"},attrs:{"wheelControl":false,"mouseDrag":_vm.items.length > 1,"touchDrag":_vm.items.length > 1,"infiniteScroll":_vm.items.length > 1},on:{"slide":_vm.updateCarousel}},_vm._l((_vm.items),function(ref,keySlide){
var img_desktop = ref.img_desktop;
var img_mobile = ref.img_mobile;
var title = ref.title;
var subtitle = ref.subtitle;
var link = ref.link;
var target = ref.target;
return _c('hooper-slide',{key:keySlide,staticClass:"item"},[_c('v-card',{attrs:{"href":link,"target":target ? '_blank' : '_self'}},[_c('img',{staticClass:"d-block item-img",attrs:{"src":_vm.files +
                (_vm.xs && img_mobile ? img_mobile : img_desktop) +
                (_vm.xs ? '?resize=1&w=600' : '?resize=1&w=1920'),"width":"100%"}}),(title || subtitle)?_c('div',{staticClass:"d-flex align-center item-content"},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[(title)?_c('div',{staticClass:"\n                        white--text\n                        text-h5 text-sm-h4 text-lg-h3 text-center\n                        font-weight-bold\n                        item-text\n                      "},[_vm._v(" "+_vm._s(title)+" ")]):_vm._e(),(subtitle)?_c('div',{staticClass:"\n                        white--text\n                        text-subtitle-1 text-sm-h6 text-lg-h5 text-center\n                        item-text\n                      ",class:{ 'mt-3': title }},[_vm._v(" "+_vm._s(subtitle)+" ")]):_vm._e()])],1)],1)],1):_vm._e()])],1)}),1),(_vm.items.length > 1)?_c('div',{staticClass:"d-flex justify-space-between px-3 px-md-4 px-lg-5 slides-nav"},[_c('a',{staticClass:"\n            d-flex\n            align-center\n            justify-center\n            elevation-2\n            pr-1\n            slides-nav-item\n          ",on:{"click":function($event){$event.preventDefault();return _vm.slidePrev.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"primary","size":_vm.xs ? 20 : 30}},[_vm._v(" fa-angle-left ")])],1),_c('a',{staticClass:"\n            d-flex\n            align-center\n            justify-center\n            elevation-2\n            pl-1\n            slides-nav-item\n          ",on:{"click":function($event){$event.preventDefault();return _vm.slideNext.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"primary","size":_vm.xs ? 20 : 30}},[_vm._v(" fa-angle-right ")])],1)]):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }