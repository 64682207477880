<template>
  <v-card class="item" color="transparent" :to="to" flat tile>
    <div class="position-relative">
      <img
        class="d-block rounded-lg item-img"
        :src="files + img + '?resize=1&w=460'"
        :alt="title"
        width="100%"
      />

      <div class="primary white--text text-center rounded-lg pa-2 item-date">
        <div class="text-h5 text-md-h4 font-weight-bold">
          {{ formatDate(scheduling, { day: "numeric" }) }}
        </div>

        <div class="text-caption text-uppercase mt-n1">
          {{ formatDate(scheduling, { month: "short" }).replaceAll(".", "") }},
          {{ formatDate(scheduling, { year: "numeric" }) }}
        </div>
      </div>
    </div>

    <div class="grey--text text-body-1 font-weight-medium py-4">
      {{ title }}
    </div>
  </v-card>
</template>

<script>
import { formatDate } from "../../utils/formatDate";

export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    scheduling: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
  methods: {
    formatDate,
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-img {
    aspect-ratio: 275 / 200;
    -o-object-fit: cover;
    object-fit: cover;
  }

  &-date {
    bottom: 0;
    position: absolute;
    right: 0;
  }
}
</style>
