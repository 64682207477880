<template>
  <v-app-bar color="white" height="145px" flat tile>
    <v-container class="py-0">
      <v-sheet
        width="100%"
        height="100%"
        color="transparent"
        class="d-flex justify-space-between align-center"
      >
        <router-link to="/">
          <img
            v-if="informations.img"
            class="d-block"
            :src="files + informations.img"
            :alt="informations.name"
            height="84px"
          />
        </router-link>

        <div class="d-flex flex-column justify-center">
          <div
            v-if="informations.tel || social.length"
            class="d-flex align-center ml-auto mb-5"
          >
            <v-btn
              v-if="informations.tel"
              class="text-body-2 font-weight-bold"
              color="grey"
              :href="getTelLinkFromPhone(informations.tel)"
              text
            >
              <v-icon class="mr-2" color="primary" size="16">
                fa-phone-alt
              </v-icon>

              {{ informations.tel }}
            </v-btn>

            <v-sheet
              v-if="informations.tel && social.length"
              class="ml-2 mr-4"
              color="#C9CDCF"
              width="2px"
              height="28px"
            ></v-sheet>

            <template v-if="social.length">
              <v-btn
                v-for="{ icon, link } in social"
                :key="link"
                class="ml-2"
                color="primary"
                depressed
                x-small
                fab
              >
                <v-icon size="16px">
                  {{ icon }}
                </v-icon>
              </v-btn>
            </template>
          </div>

          <div class="d-flex">
            <v-btn
              v-for="({ text, to }, keyMenu) in menu"
              :key="keyMenu"
              class="
                text-caption text-lg-body-2 text-none text-uppercase
                font-weight-bold
                px-2
                py-5
                mx-2
                nav-link
              "
              color="grey"
              :to="to"
              active-class="nav-link-active"
              depressed
              tile
              text
            >
              {{ text }}
            </v-btn>

            <v-btn
              class="
                white--text
                text-caption text-lg-body-2 text-none text-uppercase
                font-weight-bold
                px-2 px-lg-3
                mx-2
                py-5
                rounded-lg
              "
              color="secondary"
              depressed
              to="/seja-um-representante"
            >
              Seja um Representante
            </v-btn>
          </div>
        </div>
      </v-sheet>
    </v-container>
  </v-app-bar>
</template>

<script>
import { getTelLinkFromPhone } from "../../../utils/getTelLinkFromPhone";
import { getSocial } from "../../../utils/getSocial";

export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    social() {
      return getSocial(this.informations);
    },
  },
  methods: {
    getTelLinkFromPhone,
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  &:before {
    opacity: 0 !important;
  }

  &:not(:first-child):after {
    background-color: #c9cdcf;
    content: "";
    height: 15px;
    margin-top: -7.5px;
    pointer-events: none;
    position: absolute;
    left: -9px;
    top: 50%;
    width: 2px;
  }
}
</style>
