<template>
  <div v-if="item.title" class="grid-list">
    <div>
      <TitleWidget v-if="smAndDown" class="mb-6">
        {{ item.title }}
      </TitleWidget>

      <template v-if="item.img">
        <img
          class="d-block item-img"
          :src="files + item.img + '?resize=1&w=' + (xsOnly ? 600 : 960)"
          :alt="item.title"
          width="100%"
          height="100%"
        />
      </template>
    </div>

    <v-sheet class="d-flex align-center" color="primary lighten-4">
      <v-sheet
        class="
          d-flex
          justify-center
          align-center
          white--text
          pa-6 pa-lg-10
          pl-lg-16
          mx-auto mx-md-0
        "
        color="transparent"
        max-width="680px"
      >
        <div>
          <TitleWidget v-if="!smAndDown" align="left">
            {{ item.title }}
          </TitleWidget>

          <div
            v-if="item.introduction || item.text"
            class="
              white--text
              text-body-2 text-lg-body-1 text-justify
              mt-3 mt-md-5
              ck-content
            "
            v-html="item.introduction || item.text"
          ></div>

          <ButtonWidget
            v-if="item.introduction && item.text"
            class="mt-6 mt-md-8"
            :align="smAndDown ? 'center' : 'left'"
            to="/sobre"
          >
            Saiba Mais
          </ButtonWidget>
        </div>
      </v-sheet>
    </v-sheet>
  </div>
</template>

<script>
import TitleWidget from "../widgets/TitleWidget.vue";
import ButtonWidget from "../widgets/ButtonWidget.vue";

const _config = {
  mod: "about",
};

export default {
  created() {
    this.$rest(_config.mod).get({
      save: (state, data) => (state.item = data),
    });
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
    mdOnly() {
      return this.$vuetify.breakpoint.mdOnly;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  components: {
    TitleWidget,
    ButtonWidget,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 0;
  grid-template-columns: 47% 53%;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }

  .item-img {
    aspect-ratio: 16 / 9;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (min-width: 960.001px) and (max-width: 1264px) {
      aspect-ratio: 1;
    }
  }
}
</style>
