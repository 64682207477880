<template>
  <v-sheet
    v-if="item.title"
    class="py-10 py-md-13 pt-lg-16 px-3 mx-auto"
    max-width="1000px"
  >
    <div
      v-if="item.subtitle"
      class="
        primary--text
        text--lighten-4 text-h6 text-md-h5 text-center text-uppercase
      "
    >
      {{ item.subtitle }}
    </div>

    <TitleWidget>{{ item.title }}</TitleWidget>

    <div
      v-if="item.introduction"
      class="grey--text text-body-1 text-center mt-4"
      v-html="item.introduction"
    ></div>

    <ButtonWidget
      class="mt-7"
      to="/seja-um-representante"
      align="center"
      secondary
    >
      Sabia Mais
    </ButtonWidget>
  </v-sheet>
</template>

<script>
import TitleWidget from "../widgets/TitleWidget.vue";
import ButtonWidget from "../widgets/ButtonWidget.vue";

const _config = {
  mod: "reseller",
};

export default {
  created() {
    this.$rest(_config.mod).get({ save: (state, data) => (state.item = data) });
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
  },
  components: {
    TitleWidget,
    ButtonWidget,
  },
};
</script>
