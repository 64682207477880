<template>
  <v-sheet v-if="!loading && items.length" color="#F0F5F7">
    <v-container class="py-10 py-md-13 py-lg-16">
      <TitleWidget>{{ title }}</TitleWidget>

      <v-row class="my-0">
        <v-col
          v-for="(item, keyItems) in items"
          :key="keyItems"
          class="py-0 mt-6 mt-md-10"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <ArticleCard v-bind="item" />
        </v-col>
      </v-row>

      <div class="mt-8">
        <ButtonWidget align="center" to="/blog">Ver Mais</ButtonWidget>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
import TitleWidget from "../widgets/TitleWidget.vue";
import ArticleCard from "../cards/ArticleCard.vue";
import ButtonWidget from "../widgets/ButtonWidget.vue";
import { formatToURL } from "../../utils/formatToURL";

const _config = {
  mod: "articles",
};

const _pagination = {
  itemsPerPage: 4,
};

const _data = {
  title: "Blog",
  loading: true,
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    const params = {
      ..._pagination,
    };

    this.$rest(_config.mod)
      .get({ params })
      .finally(() => (this.loading = false));
  },
  computed: {
    items() {
      return this.$rest(_config.mod)
        .list.map(({ id, img, title, scheduling }) => ({
          img,
          title,
          scheduling,
          to: `${_config.mod}/${formatToURL(title)}-${id}`,
        }))
        .filter((item, index) => (this.mdOnly && index < 3) || !this.mdOnly);
    },
    mdOnly() {
      return this.$vuetify.breakpoint.mdOnly;
    },
  },
  components: {
    TitleWidget,
    ArticleCard,
    ButtonWidget,
  },
};
</script>
