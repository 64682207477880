<template>
  <div>
    <v-app-bar class="header-mobile" color="white" flat tile>
      <router-link to="/">
        <img
          v-if="informations.img"
          :src="files + informations.img"
          :alt="informations.name"
          height="46px"
          class="d-block"
        />
      </router-link>

      <v-spacer />

      <template v-for="(item, keySocial) in social">
        <v-btn
          v-if="item"
          :key="keySocial"
          class="mr-1 ml-1 d-flex"
          :href="item.link"
          target="blank"
          color="primary"
          depressed
          x-small
          fab
        >
          <v-icon size="16">
            {{ item.icon }}
          </v-icon>
        </v-btn>
      </template>

      <v-app-bar-nav-icon color="primary" @click="drawer = !drawer" />
    </v-app-bar>

    <v-navigation-drawer
      class="header-mobile-navigation"
      color="white"
      v-model="drawer"
      fixed
      temporary
    >
      <router-link to="/" class="d-flex px-2 py-4">
        <img
          v-if="informations.img"
          :src="files + informations.img"
          :alt="informations.name"
          class="d-block mx-auto"
          height="46px"
        />
      </router-link>

      <v-divider />

      <v-list v-model="current" class="mt-2 px-0" nav>
        <v-list-item
          v-for="({ text, to }, keyMenu) in menu"
          :key="keyMenu"
          class="mb-0 mt-2"
          :to="to"
          link
        >
          <v-list-item-content>
            <v-list-item-title
              class="
                grey--text
                text-body-2 text-uppercase
                font-weight-bold
                px-4
              "
            >
              {{ text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div class="px-3 mt-2">
          <v-btn
            class="
              white--text
              text-body-2 text-none text-uppercase
              font-weight-bold
              py-5
              rounded-lg
            "
            to="/seja-um-representante"
            color="secondary"
            depressed
            large
            block
          >
            Seja um Representante
          </v-btn>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { getSocial } from "../../../utils/getSocial";

export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: this.$route.path,
      drawer: false,
    };
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    social() {
      return getSocial(this.informations);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-mobile-navigation {
  .navbar-brand {
    width: 90%;
  }
}

.dropdown-menu::v-deep {
  .v-icon {
    font-size: 14px !important;
    color: white !important;
  }

  .v-list-group__header {
    margin-bottom: 0 !important;
    margin-top: 8px;
  }

  .v-list-group__items {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
