<template>
  <v-card class="pb-5 item" :to="to" height="100%" flat tile>
    <img
      class="d-block rounded-lg item-img"
      :src="files + img + '?resize=1&w=460'"
      :alt="title"
      width="100%"
    />

    <div
      class="
        secondary--text
        text-h6 text-md-h5 text-center
        font-weight-bold
        mt-5
      "
    >
      {{ title }}
    </div>

    <div v-if="subtitle" class="grey--text text-body-2 text-center mt-3">
      {{ subtitle }}
    </div>

    <div v-if="informations.whatsapp" class="d-flex justify-center mt-auto">
      <v-btn
        class="
          text-body-2 text-none text-uppercase
          font-weight-bold
          px-sm-5 px-md-8
          mt-5
          rounded-lg
        "
        color="primary"
        :href="getWhatsappLinkFromPhone(informations.whatsapp)"
        target="_blank"
        depressed
        large
      >
        <v-icon class="mr-2" color="white" size="20"> fa-whatsapp </v-icon>
        entre em contato
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { getWhatsappLinkFromPhone } from "../../utils/getWhatsappLinkFromPhone";

export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      required: true,
    },
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
  },
  methods: {
    getWhatsappLinkFromPhone,
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-img {
    aspect-ratio: 375 / 250;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
</style>
