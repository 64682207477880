<template>
  <div>
    <SlideShowSection />
    <ProductSection />
    <AboutSection />
    <AgroSection />
    <ArticleSection />
    <ResellerSection />
  </div>
</template>

<script>
import SlideShowSection from "../components/sections/SlideShowSection.vue";
import ProductSection from "../components/sections/ProductSection.vue";
import AboutSection from "../components/sections/AboutSection.vue";
import AgroSection from "../components/sections/AgroSection.vue";
import ArticleSection from "../components/sections/ArticleSection.vue";
import ResellerSection from "../components/sections/ResellerSection.vue";

export default {
  components: {
    SlideShowSection,
    ProductSection,
    AboutSection,
    AgroSection,
    ArticleSection,
    ResellerSection,
  },
};
</script>
