import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import AgroView from "../views/AgroView.vue";
import ResellerView from "../views/ResellerView.vue";
import ArticlesListView from "../views/ArticlesListView.vue";
import ArticlesItemView from "../views/ArticlesItemView.vue";
import ProductsCategoriesView from "../views/ProductsCategoriesView.vue";
import ProductsListView from "../views/ProductsListView.vue";
import ProductsItemView from "../views/productItem/Index.vue";
import Error404View from "../views/Error404View.vue";
import PolicyPrivacyView from "../views/PolicyPrivacyView.vue";
import ContactView from "../views/ContactView.vue";
import ContactResponseView from "../views/ContactResponseView.vue";
// import UnderConstructionView from "../views/UnderConstructionView.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "Início",
    path: "/",
    component: HomeView,
  },
  {
    name: "Sobre nós",
    path: "/sobre",
    component: AboutView,
  },
  {
    name: "Agronegócio",
    path: "/agronegocio",
    component: AgroView,
  },
  {
    name: "Seja um Representante",
    path: "/seja-um-representante",
    component: ResellerView,
  },
  {
    name: "Blog",
    path: "/blog",
    component: ArticlesListView,
  },
  {
    name: "Blog",
    path: "/blog/:item",
    component: ArticlesItemView,
  },
  {
    name: "Produtos",
    path: "/produtos",
    component: ProductsCategoriesView,
  },
  {
    name: "Produtos",
    path: "/produtos/:category",
    component: ProductsListView,
  },
  {
    name: "Produto",
    path: "/produtos/:category/:item",
    component: ProductsItemView,
  },
  {
    name: "Contato",
    path: "/contato",
    component: ContactView,
  },
  {
    name: "Obrigado!",
    path: "/contato/obrigado",
    component: ContactResponseView,
  },
  {
    name: "Política de Privacidade",
    path: "/politica-de-privacidade",
    component: PolicyPrivacyView,
  },
  {
    path: "/erro404",
    name: "Erro 404",
    component: Error404View,
  },
  {
    path: "*",
    name: "Erro 404",
    component: Error404View,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
