<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <TitleWidget>
      {{ title }}
    </TitleWidget>

    <LoadingWidget v-if="loading" />

    <template v-else>
      <template v-if="items.length">
        <v-row class="mt-6" justify="center">
          <v-col
            v-for="(item, keyItems) in items"
            :key="keyItems"
            cols="12"
            sm="6"
            md="4"
          >
            <ProductCard v-bind="item" />
          </v-col>
        </v-row>

        <v-pagination
          class="mt-6"
          v-if="items.length && pagesLength"
          v-model="page"
          :length="pagesLength"
          :total-visible="6"
          flat
        ></v-pagination>
      </template>

      <v-alert v-else type="info">
        {{ noContentText }}
      </v-alert>
    </template>
  </v-container>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import ProductCard from "../components/cards/ProductCard.vue";
import { formatToURL } from "../utils/formatToURL";

const _config = {
  mod: "productsCategories",
  path: "/produtos",
};

const _pagination = {
  itemsPerPage: 16,
  returnTotalItems: 1,
};

const _data = {
  title: "Produtos",
  loading: true,
  totalItems: 0,
  noContentText: "Nenhum produto adicionado até o momento",
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.getList();
  },
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(
        ({ id, img, title, subtitle }) => ({
          img,
          title,
          subtitle,
          to: `${_config.path}/${formatToURL(`${title}-${id}`)}`,
        })
      );
    },
    pagesLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    query() {
      return this.$route.query;
    },
    page: {
      get() {
        const p = this.query.p;
        return p ? parseInt(p) : 1;
      },
      set(v) {
        const query = { ...this.query };
        if (v) query.p = v;
        else delete query.p;

        this.$router.push({ path: "/produtos", query });
      },
    },
  },
  methods: {
    getList() {
      this.loading = true;

      const params = {
        returnTotalItems: _pagination.returnTotalItems,
      };

      if (this.page) params.page = this.page;

      this.$rest(_config.mod)
        .get({
          params,
          keepCache: true,
          save: (state, data) => {
            if (_pagination.returnTotalItems) {
              this.totalItems = data.totalItems;
              state.list = data.list;
            } else state.list = data;
          },
        })
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    $route() {
      this.getList();
    },
  },
  components: {
    TitleWidget,
    LoadingWidget,
    ProductCard,
  },
};
</script>
