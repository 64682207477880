<template>
  <v-container v-if="!loading && items.length" class="py-10 py-md-13 py-lg-16">
    <TitleWidget>{{ title }}</TitleWidget>

    <v-row class="mt-6" justify="center">
      <v-col
        v-for="(item, keyItems) in items"
        :key="keyItems"
        cols="12"
        sm="6"
        md="4"
      >
        <ProductCard v-bind="item" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleWidget from "../widgets/TitleWidget.vue";
import ProductCard from "../cards/ProductCard.vue";
import { formatToURL } from "../../utils/formatToURL";

const _data = {
  title: "Produtos",
  loading: true,
};

const _config = {
  mod: "productsCategories",
  path: "/produtos",
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.$rest(_config.mod)
      .get()
      .finally(() => (this.loading = false));
  },
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(
        ({ id, img, title, subtitle }) => ({
          img,
          title,
          subtitle,
          to: `${_config.path}/${formatToURL(title)}-${id}`,
        })
      );
    },
  },
  components: {
    TitleWidget,
    ProductCard,
  },
};
</script>
