<template>
  <div class="justify-center gallery">
    <img
      class="mx-auto gallery-img"
      :src="active + '?resize=1&w=' + (smOnly ? 960 : 600)"
      width="100%"
    />

    <v-sheet :height="!smAndDown ? '500px' : '100px'">
      <v-item-group v-model="active" mandatory>
        <div class="gallery-thumbs">
          <v-item
            v-for="(img, keyItems) in items"
            v-slot="{ active, toggle }"
            :key="keyItems"
            :value="img"
          >
            <img
              @click="toggle"
              class="cursor-pointer gallery-thumb"
              :class="active && 'gallery-thumb-active'"
              :src="img + '?resize=1&w=100'"
              width="100%"
            />
          </v-item>
        </div>
      </v-item-group>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    main: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    active: 0,
  }),
  computed: {
    smOnly() {
      return this.$vuetify.breakpoint.smOnly;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    this.active = this.main;
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  display: grid;
  gap: 8px;
  grid-template-columns: auto 100px;

  @media screen and (max-width: 1264px) {
    grid-template-columns: auto 100px;
  }

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }

  &-img,
  &-thumb {
    aspect-ratio: 1;
    border: 1px solid #ccc;
    border-radius: 2px;
    max-width: 500px;
    -o-object-fit: contain;
    object-fit: contain;
  }

  &-thumbs {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;

    @media screen and (max-width: 960px) {
      grid-template-columns: repeat(auto-fit, 100px);
    }
  }

  &-thumb {
    border: 1px solid var(--v-primary-base);
  }
}

.product-gallery-widget_main-img,
.product-gallery-widget_thumb {
  border: 1px solid #ccc;
  border-radius: 2px;
}
.product-gallery-widget_thumb {
  cursor: pointer;
}
.product-gallery-widget_thumb-active {
  border: 1px solid var(--v-primary-base);
}
.product-gallery-widget_thumbs {
  overflow-y: scroll;
}

.product-gallery-widget_thumbs::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.product-gallery-widget_thumbs::-webkit-scrollbar-thumb {
  background: var(--v-secondary-base);
  border-radius: 4px;
}
.product-gallery-widget_thumbs::-webkit-scrollbar-thumb:hover {
  background: var(--v-secondary-darken1);
}
@media screen and (max-width: 960px) {
  .product-gallery-widget_thumbs {
    overflow-y: hidden;
    overflow-x: scroll;
  }
}
</style>
