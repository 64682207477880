<template>
  <v-app>
    <LoadingWidget v-if="loading" />

    <template v-else>
      <HeaderShell />

      <v-main>
        <router-view v-bind="$route.meta" />
      </v-main>

      <FooterShell />
    </template>

    <CookieBannerWidget />
    <WhatsAppButtonWidget
      v-if="informations.whatsapp_float"
      :link="informations.whatsapp_float"
    />
  </v-app>
</template>

<script>
import HeaderShell from "./components/shell/header/HeaderShell.vue";
import FooterShell from "./components/shell/FooterShell.vue";
import CookieBannerWidget from "./components/widgets/CookieBannerWidget.vue";
import LoadingWidget from "./components/widgets/LoadingWidget.vue";
import WhatsAppButtonWidget from "./components/widgets/WhatsAppButtonWidget.vue";

export default {
  data: () => ({
    loading: true,
  }),
  created() {
    this.updateDocumentTitle();
  },
  mounted() {
    this.$rest("informations")
      .get({
        save: (state, data) => (state.item = data),
      })
      .then(() => (this.loading = false));
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    name() {
      return this.informations.name || "";
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  watch: {
    $route() {
      this.updateDocumentTitle();
    },
    name() {
      this.updateDocumentTitle();
    },
  },
  methods: {
    updateDocumentTitle() {
      document.title =
        (!["/"].includes(this.$route.path) ? `${this.$route.name} | ` : "") +
        this.name;
    },
  },
  components: {
    HeaderShell,
    FooterShell,
    CookieBannerWidget,
    LoadingWidget,
    WhatsAppButtonWidget,
  },
};
</script>
