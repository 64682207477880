<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <TitleWidget>
      {{ item.title }}
    </TitleWidget>

    <div class="d-flex align-center justify-center mt-4">
      <v-icon left color="secondary" size="16px"> fa-calendar </v-icon>
      <span class="grey--text text--darken-2 text-body-1">
        {{ formatDate(item.scheduling, configDate) }}
      </span>
    </div>

    <v-row justify="center">
      <v-col cols="12" md="9">
        <img
          v-if="item.img"
          class="d-block mt-6 mt-md-8"
          :src="files + item.img + '?resize=1&w=' + (xsOnly ? 600 : 1200)"
          :alt="item.title"
          width="100%"
        />

        <div
          v-if="item.text"
          class="text-subtitle-1 mt-8 ck-content item-text"
          v-html="item.text"
        ></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import { formatDate } from "../utils/formatDate";

const _config = {
  mod: "articles",
};

export default {
  data: () => ({
    configDate: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  }),
  created() {
    this.getItem();
  },
  computed: {
    itemId() {
      return this.$route.params.item.replace(/^.*-([0-9]+)$/, "$1");
    },
    item() {
      return this.$rest(_config.mod).item;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    getItem() {
      this.$rest(_config.mod)
        .get({ id: this.itemId })
        .catch(() => this.$router.replace("/error404"));
    },
    formatDate,
  },
  watch: {
    itemId() {
      this.getItem();
    },
  },
  components: {
    TitleWidget,
  },
};
</script>
