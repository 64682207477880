<template>
  <v-container class="py-10 py-md-13 py-lg-16 item">
    <TitleWidget>
      {{ item.title || "Agronegócio" }}
    </TitleWidget>

    <v-row v-if="item.title" justify="center">
      <v-col cols="12" md="9">
        <img
          v-if="item.img"
          class="d-block mt-6 item-img"
          :src="files + item.img + '?resize=1&w=' + (xsOnly ? 600 : 1200)"
          :alt="item.title"
          width="100%"
        />

        <div
          v-if="cultures.length"
          class="d-flex flex-wrap justify-space-around mt-8 item-cultures"
        >
          <v-card
            v-for="({ img, title }, keyCultures) in cultures"
            :key="keyCultures"
            flat
            tile
          >
            <v-sheet
              class="d-flex align-center justify-center mx-auto rounded-circle"
              color="primary lighten-4"
              height="70px"
              width="70px"
            >
              <img
                class="d-block"
                :src="files + img + '?resize=1&w=50'"
                :alt="title"
                width="60%"
              />
            </v-sheet>

            <div
              class="
                primary--text
                text--lighten-4 text-body-1 text-center
                font-weight-bold
                mt-2
              "
            >
              {{ title }}
            </div>
          </v-card>
        </div>

        <div
          v-if="item.text"
          class="text-subtitle-1 mt-7 ck-content"
          v-html="item.text"
        ></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";

const _config = {
  mod: "agribusiness",
};

export default {
  created() {
    this.$rest(_config.mod).get({
      save: (state, data) => (state.item = data),
    });
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
    cultures() {
      if (!this.item.cultures.list.length) return [];

      return this.item.cultures.list.map(({ path, title }) => ({
        img: path,
        title,
      }));
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  components: {
    TitleWidget,
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-img {
    aspect-ratio: 576 / 350;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .item-cultures {
    gap: 20px 10px;
  }
}
</style>
