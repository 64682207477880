<template>
  <div>
    <v-container class="py-10 py-md-13 py-lg-16">
      <div
        v-if="item.subtitle"
        class="
          primary--text
          text--lighten-4 text-h6 text-md-h5 text-center text-uppercase
        "
      >
        {{ item.subtitle }}
      </div>

      <TitleWidget>
        {{ item.title || "Seja um Representante" }}
      </TitleWidget>

      <v-row justify="center">
        <v-col cols="12" md="9">
          <div
            v-if="item.text"
            class="text-subtitle-1 mt-8 ck-content"
            v-html="item.text"
          ></div>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="item.map" class="map" v-html="item.map"></div>
  </div>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";

const _config = {
  mod: "reseller",
};

export default {
  created() {
    this.$rest(_config.mod).get({
      save: (state, data) => (state.item = data),
    });
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  components: {
    TitleWidget,
  },
};
</script>

<style lang="scss" scoped>
.map::v-deep {
  iframe {
    display: block;
    height: 450px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
  }
}
</style>
