<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <TitleWidget>
      {{ title }}
    </TitleWidget>

    <LoadingWidget v-if="loading" />

    <template v-if="!loading">
      <template v-if="items.length">
        <v-row class="my-0">
          <template v-for="(item, keyItems) in items">
            <v-col
              :key="keyItems"
              class="py-0 mt-6 mt-md-10"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <ArticleCard :key="keyItems" v-bind="item" />
            </v-col>
          </template>
        </v-row>

        <v-pagination
          v-if="pagesLength > 1"
          class="mt-10"
          color="secondary"
          v-model="page"
          :length="pagesLength"
        ></v-pagination>
      </template>

      <template v-else>
        <v-alert class="mt-6" type="info">
          Nenhum conteúdo adicionado até o momento.
        </v-alert>
      </template>
    </template>
  </v-container>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import ArticleCard from "../components/cards/ArticleCard.vue";
import { formatToURL } from "../utils/formatToURL";

const _config = {
  mod: "articles",
  title: "Blog",
  path: "/blog",
  itemsPerPage: 24,
  returnTotalItems: 1,
};

export default {
  data: () => ({
    loading: true,
    title: _config.title,
    items: [],
    totalItems: 0,
  }),
  created() {
    this.getList();
  },
  computed: {
    page: {
      get() {
        const p = this.$route.query.p;
        return p ? parseInt(p) : 1;
      },
      set(v) {
        const query = { ...this.$route.query };
        query.p = v;

        return this.$router.push({ query });
      },
    },
    pagesLength() {
      return Math.ceil(this.totalItems / _config.itemsPerPage);
    },
  },
  methods: {
    getList() {
      this.loading = true;

      const params = {
        itemsPerPage: _config.itemsPerPage,
        returnTotalItems: _config.returnTotalItems,
        page: this.page,
      };

      this.$rest(_config.mod)
        .get({ params })
        .then((data) => {
          let items = [];
          if (_config.returnTotalItems) {
            items = data.list;
            this.totalItems = data.totalItems;
          } else {
            items = data;
          }

          this.items = items.map(({ id, img, title, scheduling }) => ({
            img,
            title,
            scheduling,
            to: `${_config.path}/${formatToURL(title)}-${id}`,
          }));
        })
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    page() {
      this.getList();
    },
  },
  components: {
    TitleWidget,
    LoadingWidget,
    ArticleCard,
  },
};
</script>
