<template>
  <div class="position-relative slides slop">
    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <div v-if="!loading && items.length">
        <hooper-container
          class="slides-container"
          style="height: auto"
          ref="carousel"
          :wheelControl="false"
          :mouseDrag="items.length > 1"
          :touchDrag="items.length > 1"
          :infiniteScroll="items.length > 1"
          @slide="updateCarousel"
        >
          <hooper-slide
            class="item"
            v-for="(
              { img_desktop, img_mobile, title, subtitle, link, target },
              keySlide
            ) in items"
            :key="keySlide"
          >
            <v-card :href="link" :target="target ? '_blank' : '_self'">
              <img
                class="d-block item-img"
                :src="
                  files +
                  (xs && img_mobile ? img_mobile : img_desktop) +
                  (xs ? '?resize=1&w=600' : '?resize=1&w=1920')
                "
                width="100%"
              />

              <div
                v-if="title || subtitle"
                class="d-flex align-center item-content"
              >
                <v-container>
                  <v-row justify="center">
                    <v-col cols="12" md="10">
                      <div
                        v-if="title"
                        class="
                          white--text
                          text-h5 text-sm-h4 text-lg-h3 text-center
                          font-weight-bold
                          item-text
                        "
                      >
                        {{ title }}
                      </div>

                      <div
                        v-if="subtitle"
                        class="
                          white--text
                          text-subtitle-1 text-sm-h6 text-lg-h5 text-center
                          item-text
                        "
                        :class="{ 'mt-3': title }"
                      >
                        {{ subtitle }}
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-card>
          </hooper-slide>
        </hooper-container>

        <div
          v-if="items.length > 1"
          class="d-flex justify-space-between px-3 px-md-4 px-lg-5 slides-nav"
        >
          <a
            class="
              d-flex
              align-center
              justify-center
              elevation-2
              pr-1
              slides-nav-item
            "
            @click.prevent="slidePrev"
          >
            <v-icon color="primary" :size="xs ? 20 : 30">
              fa-angle-left
            </v-icon>
          </a>
          <a
            class="
              d-flex
              align-center
              justify-center
              elevation-2
              pl-1
              slides-nav-item
            "
            @click.prevent="slideNext"
          >
            <v-icon color="primary" :size="xs ? 20 : 30">
              fa-angle-right
            </v-icon>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";

const _config = {
  mod: "slides",
};

export default {
  data: () => ({
    loading: true,
    carouselData: 0,
  }),
  computed: {
    items() {
      return this.$rest(_config.mod).list;
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  mounted() {
    this.$rest(_config.mod)
      .get()
      .then(() => (this.loading = false));
  },
  methods: {
    slidePrev() {
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.$refs.carousel.slideNext();
    },
    updateCarousel(payload) {
      this.myCarouselData = payload.currentSlide;
    },
  },
  watch: {
    carouselData() {
      this.$refs.carousel.slideTo(this.carouselData);
    },
  },
  components: {
    LoadingWidget,
  },
};
</script>

<style lang="scss" scoped>
.slides-container::v-deep {
  ul,
  ol {
    padding: 0 !important;
  }
}

.slides {
  aspect-ratio: 1920 / 680;

  @media screen and (max-width: 600px) {
    aspect-ratio: 600 / 600;
  }

  .item {
    &-img {
      aspect-ratio: 1920 / 680;
      object-fit: cover;
      -o-object-fit: cover;

      @media screen and (max-width: 600px) {
        aspect-ratio: 600 / 600;
      }
    }

    &-content {
      bottom: 0;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }

    &-text {
      pointer-events: initial;
    }
  }

  &-nav {
    height: 0;
    left: 0;
    margin-top: -25px;
    position: absolute;
    top: 50%;
    width: 100%;

    @media screen and (max-width: 600px) {
      margin-top: -20px;
    }

    &-item {
      background-color: #ffffff;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      @media screen and (max-width: 600px) {
        height: 40px;
        width: 40px;
      }
    }
  }
}
</style>
