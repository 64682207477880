<template>
  <div
    v-if="informations.whatsapp"
    class="d-flex justify-center justify-lg-start mt-auto"
  >
    <v-btn
      class="
        text-body-1 text-md-h6 text-none text-uppercase
        font-weight-bold
        px-sm-5 px-md-8
        mt-5
        rounded-lg
      "
      color="primary"
      :href="getWhatsappLinkFromPhone(informations.whatsapp)"
      target="_blank"
      depressed
      x-large
    >
      <v-icon class="mr-2" color="white" :size="smAndDown ? 20 : 24">
        fa-whatsapp
      </v-icon>
      entre em contato
    </v-btn>
  </div>
</template>

<script>
import { getWhatsappLinkFromPhone } from "../../utils/getWhatsappLinkFromPhone";

export default {
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    getWhatsappLinkFromPhone,
  },
};
</script>
