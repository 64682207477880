<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <TitleWidget>
      {{ title }}
    </TitleWidget>

    <v-row class="mt-6" justify="center">
      <v-col cols="12" md="7" lg="6">
        <ContactForm
          :action="files + formAction"
          response-to="/contato/obrigado"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import ContactForm from "../components/forms/ContactForm.vue";

const _data = {
  title: "Contato",
  formAction: "/rest/email/contact-us",
};
export default {
  data: () => ({
    ..._data,
  }),
  components: {
    TitleWidget,
    ContactForm,
  },
};
</script>
