// const server =
//   process.env.NODE_ENV === "development"
//     ? process.env.VUE_APP_SERVER
//     : location.origin;

const server = process.env.VUE_APP_SERVER;

const menu = [
  {
    text: "Início",
    to: "/",
  },
  {
    text: "Sobre",
    to: "/sobre",
  },
  {
    text: "Produtos",
    to: "/produtos",
  },
  {
    text: "Agronegócio",
    to: "/agronegocio",
  },
  {
    text: "Blog",
    to: "/blog",
  },
  {
    text: "Contato",
    to: "/contato",
  },
];

export { server, menu };
