<template>
  <footer class="shell-footer">
    <v-sheet color="primary lighten-4" class="position-relative">
      <v-container class="py-6">
        <div class="justify-center justify-md-space-between grid-list">
          <div class="grid-list-item">
            <router-link to="/">
              <img
                v-if="informations.img"
                :src="files + informations.img"
                height="80px"
                class="d-block mx-auto mx-md-0"
              />
            </router-link>
          </div>

          <div class="grid-item">
            <div class="d-flex justify-center justify-lg-end align-center">
              <div
                v-for="({ text, link, icon, size }, keySocial) in social"
                :key="keySocial"
                class="d-inline-flex"
              >
                <v-btn
                  class="ml-2"
                  :href="link"
                  target="_blank"
                  min-width="auto"
                  color="primary"
                  small
                  :title="text"
                  depressed
                  rounded
                  fab
                >
                  <v-icon color="white" :size="size">{{ icon }}</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </v-sheet>

    <v-sheet color="primary lighten-4">
      <v-container
        class="
          pt-0
          pb-5
          d-flex
          flex-column flex-sm-row
          justify-space-between
          align-center
          text-center
        "
      >
        <div class="white--text text-body-2 mt-5">
          Copyright ©
          <b class="font-weight-bold">{{ year }} {{ informations.name }}</b
          >.
          <span class="d-block d-sm-inline-block">
            Todos os direitos reservados.
          </span>
        </div>
        <a
          class="d-flex mt-5"
          href="https://www.mrxweb.com.br/"
          target="_blank"
        >
          <img
            class="shell-footer-logo"
            src="../../assets/img/mrxweb.svg"
            alt="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
            title="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
            height="20px"
          />
        </a>
      </v-container>
    </v-sheet>
  </footer>
</template>

<script>
import { getContact } from "../../utils/getContact";
import { getSocial } from "../../utils/getSocial";

export default {
  data: () => ({
    year: new Date().getFullYear(),
  }),
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    contacts() {
      return getContact(this.informations);
    },
    social() {
      return getSocial(this.informations);
    },
  },
};
</script>

<style lang="scss" scoped>
.shell-footer {
  .grid-list {
    display: grid;
    gap: 24px 48px;
    grid-template-columns: auto auto;

    @media screen and (max-width: 960px) {
      gap: 24px;
      grid-template-columns: auto;
    }

    .btn-contact::v-deep {
      .v-btn__content {
        max-width: 100%;
        white-space: normal;
      }
    }
  }
}
</style>
